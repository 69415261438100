import React, { useState } from "react";
import { useFormInput } from "../../hooks/useFormInput";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Hero = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const email = useFormInput("");
  const emailVal = email.value;
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); //--> prevent the page from reloading on form submit
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return;
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("homepage");

    // Prepare the data for the server, specifically body-parser
    const data = JSON.stringify({
      email: emailVal,
      token: result,
    });

    fetch("https://app.timeview.io/api/companies/requestinfo", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(
            "Ha ocurrido un error, intentálo más tarde o contacta con nosotros a través de hola@timeview.io"
          );
        }

        return res.json();
      })
      .then((data) => {
        setError("");
        setFeedback(
          "¡Hemos recibido tu solicitud! Pronto nos pondremos en contacto contigo."
        );
      })
      .catch((error) => {
        setFeedback("");
        setError(error?.message);
      });
  };

  return (
    <div className="pt-23 pt-md-26 pt-lg-28">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-8 col-md-11">
            <div
              className="text-center mb-10 mb-lg-10"
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h2 className="font-size-11 mb-5">Precios y planes</h2>
              <p className="font-size-8 mb-0 text-buttercup">
                15 días de prueba <strong>gratis</strong> con soporte continuo
              </p>
              <p className="font-size-5 mb-7">
                Los precios mostrados a continuación son para empresas
                inferiores a 50 personas
              </p>
              <form
                className="register-form form-mini p-8"
                action="/"
                // data-aos="fade-up"
                // data-aos-duration={800}
                // data-aos-delay={500}
                onSubmit={handleSubmit}
              >
                {/* Email */}
                <div className="form-group text-black font-size-4">
                  Sabemos que no todas las empresas son iguales. Dinos tu correo
                  y estaremos encantados de buscar la mejor solución que se
                  adapte a ti.
                </div>
                <div className="form-group form-group-inline mb-0 position-relative">
                  <input
                    value={emailVal}
                    onChange={email.onChange}
                    type="email"
                    name="email"
                    className="w-100 bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 mr-4"
                    placeholder="Introduce tu correo"
                    id="email"
                    required
                  />
                  <button
                    type="submit"
                    className="btn btn-sm btn-blue-3 w-50 rounded-4"
                    disabled={!emailVal}
                  >
                    Contactar
                  </button>
                </div>
              </form>
              {feedback && (
                <h6 className="font-size-5 text-green mb-5 mb-lg-9 mt-5 font-weight-normal">
                  {feedback}
                </h6>
              )}
              {error && (
                <h6 className="font-size-5 text-red mb-5 mb-lg-9 mt-5 font-weight-normal">
                  {error}
                </h6>
              )}
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
