import React from "react";

const Faq = () => {
  return (
    <div className="pb-13 pb-md-18 pb-lg-25">
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <div className="text-center mb-13 mb-lg-19">
              <h2 className="font-size-9 mb-0">Preguntas frecuentes</h2>
            </div>
          </div>
        </div>
        {/* End Section Title */}
        {/* Faqs */}
        {/* Faqs */}
        <div className="row justify-content-center">
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  ¿Cómo funciona el periodo de prueba de 15 días?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Para empezar a probar Timeview, tan solo tienes que contactar
                  con nosotros. Al hacerlo te prepararemos un acceso que se
                  ajuste a tus necesidades y podrás explorar y probar todas las
                  funcionalidades sin límite durante 15 días.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  ¿Tengo que introducir mi tarjeta de crédito para probar
                  Timeview?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  No, para probar Timeview solo tienes que contactar con
                  nosotros y no te pediremos ningun método de pago.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  ¿Qué pasa cuando acabe mi periodo de prueba de 15 días?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Se te informará de que tu periodo de prueba ha expirado. Para
                  poder seguir utilizando Timeview, deberás seleccionar uno de
                  los planes. Si necesitas más tiempo para probar Timeview{" "}
                  <a
                    className="text-blue-3 btn-link-with-underline"
                    href="mailto:hola@timeview.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ponte en contacto con nosotros
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  Mi empresa es muy grande, ¿tengo que cargar todos mis datos
                  manualmente?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Nosotros te ayudamos en la puesta en marcha de tu empresa para
                  que disfrutes de Timeview lo antes posible, ¡no te preocupes!
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  ¿Y si tengo dudas de como funciona algún módulo?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Lo que nos diferencia es el soporte y la atención con nuestros
                  clientes. Nuestro equipo siempre estará ahí para ayudarte y
                  guiarte.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  ¿Puedo cancelar mi suscripción en cualquier momento?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Sí. Una vez cancelada podrás seguir utilizando Timeview hasta
                  la siguiente fecha de renovación.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">¿Puedo mejorar mi plan?</h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Sí, siempre que quieras. ¡Solo tienes que contactar con
                  nosotros!
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">¿Hay aplicación móvil?</h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Sí, nuestra aplicación está disponible tanto para Android como
                  para iOS. Puedes descubrir más información accediendo a la
                  sección de <a href="/product/app">Fichar</a>.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
        </div>
        {/* Button  */}
        <div
          className="text-center pt-lg-3 pb-lg-3"
          data-aos="fade-up"
          data-aos-duration={500}
        >
          <p className="font-size-6 mb-0">
            ¿Aún tienes preguntas sobre Timeview?
            <a
              className="text-blue-3 btn-link-with-underline ml-5"
              href="mailto:hola@timeview.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              ¡Pregúntanos!
            </a>
          </p>
        </div>
        {/* Button End */}
      </div>
    </div>
  );
};

export default Faq;
