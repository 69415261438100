import React, { useState } from "react";
import { Link } from "gatsby";

const Pricing = () => {
  const [plan, setPlan] = useState("mensual");

  // const pricings
  const PricingData = [
    {
      title: "Core",
      color: "#FFC24D",
      list: [
        "Ficha empleado",
        "Expediente del empleado",
        "Gestión acreditaciones",
        "Gestión documental",
        "Incidencias",
        "Firma digital",
        "Notificaciones",
        "Teletrabajo",
        "Copias de seguridad y mantenimiento",
      ],
      price: plan === "mensual" ? "3,50 €" : "3,20 €",
      min: plan === "mensual" ? "52,50 €" : "48,00 €",
    },
    {
      title: "Control",
      color: "#4DD1FF",
      list: [
        <b style={{ color: "#FFC24D" }}>Timeview Core</b>,
        "Control de fichajes",
        "Planificación manual",
        "Turnos y patrones",
        "Ausencias y vacaciones",
        "Horas extras",
        "Resumen de equipo",
        "Permutas",
        "Centros de trabajo",
        "Gestión de dispositivos de fichaje",
      ],
      price: plan === "mensual" ? "4,75 €" : "4,35 €",
      min: plan === "mensual" ? "71,25 €" : "65,25 €",
    },
    {
      title: "Experience",
      color: "#1bea7a",
      list: [
        <b style={{ color: "#FFC24D" }}>Timeview Core</b>,
        <b style={{ color: "#4DD1FF" }}>Timeview Control</b>,
        "Proyectos",
        "Viajes",
        "Moneda virtual y material del empleado",
      ],
      price:
        plan === "mensual" ? "Precio personalizado" : "Precio personalizado",
    },
  ];

  return (
    <div className="pb-5 pb-md-11 pb-lg-19">
      <div className="container">
        {/* Switcher */}
        <div className="col-xl-12 col-lg-12 col-md-12 text-center text-md-center mb-10">
          <div
            className="btn-section"
            data-aos="fade-left"
            data-aos-delay={500}
          >
            <div className="btn-toggle-square active mx-3 price-deck-trigger rounded-10 bg-golden-yellow">
              <button
                className={`text-break btn-reset focus-reset ${plan !==
                  "anual" && "active"}`}
                onClick={() => setPlan("anual")}
              >
                Anual
              </button>
              <button
                className={`text-break btn-reset focus-reset ${plan !==
                  "mensual" && "active"}`}
                onClick={() => setPlan("mensual")}
              >
                Mensual
              </button>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={900}
              data-aos-once="true"
            >
              <p
                className="font-size-9 mb-4"
                style={{ color: PricingData[0].color }}
              >
                {PricingData[0].title}
              </p>
              <h2 className="font-size-11 mb-1">{PricingData[0].price}</h2>
              <span className="font-size-4 mb-6 text-dark-cloud">
                usuario/mes
              </span>
              <span className="font-size-5 mb-2">
                Importe mínimo {PricingData[0].min}
              </span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                {PricingData[0].list.map((item, index) => (
                  <li className="mb-7" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="pt-7 pt-lg-17">
                <Link className="btn btn-blue-3 btn-2 rounded-5" to="/contact">
                  Contacta con nosotros
                </Link>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={1200}
              data-aos-once="true"
            >
              <p
                className="font-size-9 mb-4"
                style={{ color: PricingData[1].color }}
              >
                {PricingData[1].title}
              </p>
              <h2 className="font-size-11 mb-1">{PricingData[1].price}</h2>
              <span className="font-size-4 mb-6 text-dark-cloud">
                usuario/mes
              </span>
              <span className="font-size-5 mb-2">
                Importe mínimo {PricingData[1].min}
              </span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                {PricingData[1].list.map((item, index) => (
                  <li className="mb-7" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="pt-7 pt-lg-17">
                <Link className="btn btn-blue-3 btn-2 rounded-5" to="/contact">
                  Contacta con nosotros
                </Link>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="true"
            >
              <p
                className="font-size-9 mb-4"
                style={{ color: PricingData[2].color }}
              >
                {PricingData[2].title}
              </p>
              <h2 className="font-size-9 mb-13 text-center">
                {PricingData[2].price}
              </h2>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                {PricingData[2].list.map((item, index) => (
                  <li className="mb-7" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="pt-7 pt-lg-17">
                <Link className="btn btn-blue-3 btn-2 rounded-5" to="/contact">
                  Contacta con nosotros
                </Link>
              </div>
            </div>
          </div>
          {/* End Single Table */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
