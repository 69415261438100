import { useState } from "react";

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setValue(e.target.checked);
    } else {
      setValue(e.target.value);
    }
  };
  return {
    value,
    onChange: handleChange,
  };
};
